import React, { useEffect } from 'react';
import useGlobal from "../../store";
import server from '../../api/server';
import { WAITING, ACTIVE, PASS, INVESTIGATION, RESERVED } from '../../components/statusTypes';
import { workstations } from '../../components/dbNames';
import '../../components/style.css';

const WorkstationScreen = props => {
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        globalActions.getData(workstations, '{}', globalState.token);
    }, []);

    const selectWorkstation = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configWorkstation', event.target.id);
            globalActions.configureAction('configWorker', undefined);
        }
    }

    function resetCycleCount(event) {
        if (event !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post('/resetCycleCount', { name: event.target.id }, config)
                .then((response) => {
                    globalActions.getData(workstations, '{}', globalState.token);
                });
        }
    }

    function setWorkstationEmpty(event) {
        if (event !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post('/setWorkstationEmpty', { name: event.target.id}, config)
                .then((response) => {
                    globalActions.getData(workstations, '{}', globalState.token);
                });
        }
    }

    function setOnlineOffine(event) {
        if (event !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post('/setOnlineOffline', { name: event.target.id }, config)
                .then((response) => {
                    globalActions.getData(workstations, '{}', globalState.token);
                });
        }
    }

    function setComplete(event) {
        if (event !== undefined) {
            var id = -1;            
            for (var i = 0; i < globalState.workstations.length; i++) {
                if (globalState.workstations[i]._id === globalState.configWorkstation) {
                    id = i;
                    break;
                }
            }
            var name = globalState.workstations[id].name;
            var poNum = globalState.workstations[id].poInStation[0];
            var stationStatus = WAITING;
            var stepStatus = event.target.id;
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post('/setWorkstationComplete', 
                {
                    name,
                    poNum,
                    stationStatus,
                    stepStatus
                }, config)
                .then((response) => {
                    globalActions.getData(workstations, '{}', globalState.token);
                });
        }
    }

    function renderWorkstationDetails(){
        if (globalState.workstations !== undefined && globalState.configWorkstation !== undefined) {
            var id = -1;            
            for (var i = 0; i < globalState.workstations.length; i++) {
                if (globalState.workstations[i]._id === globalState.configWorkstation) {
                    id = i;
                    break;
                }
            }
            return (
                <div className="editWindow1">     
                    <h4>{globalState.workstations[id].name}</h4>
                    <div className="grid">
                        <div className="ui link list p11">
                            <h5>Workstation Controls</h5>
                            <div className="item">
                                <button className="button" id={globalState.workstations[id].name} onClick={resetCycleCount}>Reset Cycle Count</button>
                            </div>
                            <div className="item">
                                <button className="button" id={globalState.workstations[id].name} onClick={setWorkstationEmpty}>Set Workstation empty</button>
                            </div>

                            <div className="item">
                                <button className="button" id={globalState.workstations[id].name} onClick={setOnlineOffine}>Set ONLINE/OFFLINE</button>
                            </div>
                        </div>
                        <div className="ui link list p12">
                            <h5>PO Controls</h5>
                            <div className="item">
                                <button className="button" id={PASS} onClick={setComplete}>Set PASS</button>
                            </div>
                            <div className="item">
                                <button className="button" id={INVESTIGATION} onClick={setComplete}>Set INVESTIGATION</button>
                            </div>
                        </div>

                    </div>
               
                    <div className="grid">
                        <div className="workstationDescription1">
                            <h5>
                                Status: {globalState.workstations[id].status}
                            </h5>
                            <h5>Workstation Groups:</h5>
                            <div className="ui bulleted list">
                            {globalState.workstations[id].workstationGroup.map(workstationGroup => (
                                    <div className="item">
                                        {workstationGroup}
                                    </div>
                                ))}
                            </div>

                            <h5>Grid Location:</h5>
                            <div className="ui bulleted list">
                                <div className="item">
                                    posX: {globalState.workstations[id].gridLocation.posX}
                                </div>
                                <div className="item">
                                    posY: {globalState.workstations[id].gridLocation.posY}
                                </div>
                                <div className="item">
                                    posZ: {globalState.workstations[id].gridLocation.posZ}
                                </div>

                                <div className="item">
                                    orentX: {globalState.workstations[id].gridLocation.orientX}
                                </div>
                                <div className="item">
                                    orentY: {globalState.workstations[id].gridLocation.orientY}
                                </div>
                                <div className="item">
                                    orentZ: {globalState.workstations[id].gridLocation.orientZ}
                                </div>
                                <div className="item">
                                    orentW: {globalState.workstations[id].gridLocation.orientW}
                                </div>
                            </div>
                            <h5>
                                Cycles: {globalState.workstations[id].cycles}
                            </h5>
                            <h5>
                                Cycles between certifications: {globalState.workstations[id].cyclesToCertify}
                            </h5>
                        </div>
                        <div className="workstationDescription2">
                            {globalState.workstations[id].poInStation.map(po => (
                                <h5>
                                    Po in Station: {po}
                                </h5>
                            ))}
                            <h5>
                                Time activated: {globalState.workstations[id].timeActivated}
                            </h5>
                            <h5>
                                Step # (start is index 0): {globalState.workstations[id].stepIndex}
                            </h5>                            
                        </div>

                    </div>
                </div>
            );
        } else {            
            return <div>Select a workstation to view details</div>
        }
    }

    function renderWorkstation() {
        if (globalState.workstations === undefined) {
            return (<div>Loading....</div>)
        } else {
            return (
                <div>
                    <div className="grid">
                        <div className="quality1">                            
                            <div className="ui message">
                                <h4>
                                    Workstation Activity
                                </h4>
                            </div>
                            {globalState.workstations.map((ws, index) => {
                                let poInProgress = 'none';
                                if (ws.poInProgress !== '') {
                                    poInProgress = ws.poInStation[0];
                                }

                                let icon = 'exclamation triangle icon'
                                var selected = "poContent"

                                switch (ws.status) {
                                    case WAITING:
                                        if (ws.poInStation[0] !== 'empty' && !ws.name.includes('Buffer')) {
                                            selected = "poContent partWaiting"
                                        } else {
                                            selected = "poContent waiting"
                                        }                                        
                                        icon = "heart outline icon";
                                        break;
                                    case ACTIVE:
                                        selected = "poContent active";
                                        icon = "cogs icon";
                                        break;
                                    case RESERVED:
                                        selected = "poContent reserved";
                                        icon = "download icon";
                                        break;
                                    default:
                                        selected = "poContent error";
                                        icon = "exclamation triangle icon";
                                        break;
                                }

                                if (globalState.configWorkstation === ws._id) {
                                    selected += " selected"
                                }

                                return (
                                    <div className={selected} id={ws._id} onClick={selectWorkstation} >
                                        <div className="header" id={ws._id} onClick={selectWorkstation}>
                                            <i className={icon}></i>
                                            WS Name: {ws.name}
                                            <div id={ws._id} onClick={selectWorkstation} >PO in Station: {poInProgress}</div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                        <div className="editWindow">{renderWorkstationDetails()}</div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            {renderWorkstation()}
        </div>
    );
}

export default WorkstationScreen;