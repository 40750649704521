import React, { useEffect, useState } from 'react';
import '../style.css';
import { customers } from '../dbNames';
import useGlobal from '../../store';
import server from '../../api/server'

const CustomerConfig = () => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({
        status: 'Ready to configure customer',
        input: ['', 0]
    });
    const allowedUpdates = ['name', 'priority']

    useEffect(() => {
        globalActions.getData(customers, '{}', globalState.token);
        globalActions.configureAction('configCustomer', undefined)
    }, []);

    const selectCustomer = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configCustomer', event.target.id);
            var ws = '';
            for (var i = 0; i < globalState.customers.length; i++) {
                if (globalState.customers[i]._id === event.target.id) {
                    ws = globalState.customers[i];
                    break;
                }
            }
            var array = state.input
            allowedUpdates.map((field, index) => {
                array[index] = ws[field]
            })
            setState({ ...state, status: 'Ready to configure customer', input: array })
        }
    }

    const newCustomer = (event) => {
        if (event !== undefined) {
            setState({ ...state, input: ['**name**', 0] })
            globalActions.configureAction('configCustomer', event.target.id)
        }
    }

    function renderStatus() {
        if (globalState.customers === undefined) {
            return (<p>Return to home</p>)
        } else {
            return (<p>{state.status}</p>);
        }
    }

    const submitChanges = (event) => {
        if (event !== undefined) {
            var z = document.getElementById("frm1")
            var updateObject = {}
            for (var i = 0; i < z.length; i++) {
                if (z.elements[i].value.indexOf(',') === -1) {
                    updateObject[z.elements[i].name] = z.elements[i].value
                } else {
                    updateObject[z.elements[i].name] = z.elements[i].value.split(',')
                }
            }
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (globalState.configCustomer === 'new') {
                server.post(`/customers`, updateObject, config)
                    .then((response) => {
                        if (response.status === 201) {
                            setState({ ...state, status: 'Customer successfully created' });
                            globalActions.getData(customers, '{}', globalState.token);
                            globalActions.configureAction('configCustomer', response.data._id)
                        } else {
                            setState({ ...state, status: `Their was an error in customer update, check data entered` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = `Problem saving customer error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            } else {
                server.patch(`/customers/${globalState.configCustomer}`, updateObject, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Customer successfully updated' });
                            globalActions.getData(customers, '{}', globalState.token);
                        } else {
                            setState({ ...state, status: `Their was an error in customer update, check data entered` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = `Problem saving customer error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            }
        }
    }

    function deleteCustomer(event) {
        if (event !== undefined) {
            var confirmation = window.confirm('Are you sure you want to delete?')
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (confirmation === true) {
                server.delete(`/customers/${globalState.configCustomer}`, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Customer successfully deleted' });
                            globalActions.getData(customers, '{}', globalState.token);
                            globalActions.configureAction('configCustomer', undefined)
                        } else {
                            setState({ ...state, status: `Their was an error deleting the customer` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = `Problem deleting customer error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            }
        }
    }

    function renderDeleteButton() {
        if (globalState.configCustomer !== 'new') {
            return (
                <div className="button" id="delete" onClick={deleteCustomer}>
                    DELETE
                </div>
            )
        } else {
            return <div></div>
        }
    }

    function renderCustomers() {
        if (globalState.customers === undefined) {
            return (<div>Loading....</div>)
        } else {

            return (
                <div>
                    <div className="ui message">
                        <h2>
                            Customer Configuration
                            <div className="button" id="new" onClick={newCustomer}>
                                NEW
                            </div>
                        </h2>

                    </div>
                    <div className="grid">
                        <div className="quality1">
                            {globalState.customers.map(cs => {
                                var selected = "poContent"
                                if (cs._id === globalState.configCustomer) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div className={selected} id={cs._id} onClick={selectCustomer} >
                                        <div className="header" id={cs._id}>
                                            <i className="industry icon" id={cs._id}></i>
                                            Name: {cs.name}
                                        </div>
                                        <div className="header" id={cs._id}>
                                            <i className="qrcode icon" id={cs._id}></i>
                                            ID: {cs._id}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="editWindow">{renderEditWindow()}</div>
                    </div>
                </div>
            )
        }
    }

    function renderEditWindow() {
        if (globalState.configCustomer === undefined) {
            return (<div>Please select a customer to configure or New to create a new customer</div>)
        } else {
            var cs = {}
            for (var i = 0; i < globalState.customers.length; i++) {
                if (globalState.customers[i]._id === globalState.configCustomer) {
                    cs = globalState.customers[i];
                    break;
                }
            }
            return (
                <div>
                    <div>
                        <div className="spacing">_id: {globalState.configCustomer}</div>
                        <form id="frm1">

                            {allowedUpdates.map((field, index) => {
                                var value = undefined
                                var type = typeof state.input[index]

                                switch (type) {
                                    case 'string':
                                        value = cs[field]
                                        break;
                                    case 'object':
                                        if (Array.isArray(state.input[index])) {
                                            // HERE WE NEED TO HANDLE IF THERE IS MORE THAN ONE ELEMENT IN THE ARRAY
                                            value = state.input[index]
                                        } else {
                                            value = 'object'
                                        }
                                        break;
                                    case 'number':
                                        value = state.input[index]
                                        break;
                                    default:
                                        break;
                                }

                                if (value === 'object') {
                                    const names = Object.keys(state.input[index])
                                    return (
                                        <div className="spacing">
                                            {field}:
                                            <input
                                                className="input"
                                                type={typeof state.input[index].x}
                                                name={names[0]}
                                                value={state.input[index].x}
                                                onChange={e => {
                                                    var array = state.input
                                                    array[index] = { x: e.target.value, y: state.input[index].y }
                                                    setState({ ...state, input: array })
                                                }}
                                            />
                                            <input
                                                className="input"
                                                type={typeof state.input[index].y}
                                                name={names[1]}
                                                value={state.input[index].y}
                                                onChange={e => {
                                                    var array = state.input
                                                    array[index] = { x: state.input[index].x, y: e.target.value }
                                                    setState({ ...state, input: array })
                                                }}
                                            />

                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="spacing">
                                            {field}:

                                            <input
                                                className="input"
                                                type={type}
                                                name={field}
                                                value={state.input[index]}
                                                onChange={e => {
                                                    var array = state.input
                                                    array[index] = e.target.value
                                                    setState({ ...state, input: array })
                                                }}
                                            />

                                        </div>
                                    )
                                }
                            })}
                        </form>
                        <div className="button" id="save" onClick={submitChanges}>
                            SAVE
                        </div>
                        {renderDeleteButton()}
                    </div>
                    <div>Enter multiple customer groups using comma's</div>
                    <div className="ui message">
                        <div className="header">
                            Status:
                        </div>
                        {renderStatus()}
                    </div>
                </div>
            )
        }
    }


    return (
        < div >
            {renderCustomers()}

        </div >
    )

}

export default CustomerConfig;